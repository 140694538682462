.EL-profileAvatarMain {
  display: block;
  text-align: center;
  margin: 0 auto;
  padding: 32px 0 32px 0px;
  h6 {
    margin: 12px 0 0 0px;
    font-weight: 600;
    text-transform: capitalize;
  }
  p {
    margin: 0px;
    color: #666;
  }
  .EL-profileAvatar {
    .MuiAvatar-circular {
      height: 120px;
      width: 120px;
      background: #e1e1e1;
      color: #000;
      font-size: 44px;
      text-transform: uppercase;
      svg {
        max-width: 60px;
        max-height: 60px;
      }
    }
    .MuiBadge-badge {
      background-color: #fff;
      height: 22px;
      width: 22px;
      padding: 20px;
      border-radius: 50%;
      border: 1px solid #dfdfdf;
      .MuiAvatar-circular {
        height: 40px;
        width: 40px;
        background: #fff;
      }
    }
  }
}
@media (max-width: 767px) {
  .EL-profileAvatarMain {
    border: none;
    h6 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
    .EL-profileAvatar {
      .MuiAvatar-circular {
        height: 80px;
        width: 80px;
        font-size: 36px;
        svg {
          max-width: 40px;
          max-height: 40px;
        }
      }
    }
  }
}
