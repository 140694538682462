.bookingConfirmContainer {
  padding: 0 16px;
  padding-top: 120px;
  .El-error {
    color: #ed4441;
  }
  .EL-conformContaner {
    padding-bottom: 60px;
  }
  .EL-bookingContainerHeader {
    .MuiTypography-subtitle2 {
      font-weight: 600;
      color: #10b981;
    }
  }
  h4 {
    font-size: 32px;
    font-weight: 600;
    margin: 8px 0 4px 0px;
  }
  .MuiPaper-root {
    box-shadow: none;
    &.EL-bookingConfirmPaper {
      box-shadow: 0px 2px 16px 0px #00000026;
      border-radius: 16px;
      margin-top: 24px;
    }
  }
  .El-refundMassage {
    padding: 16px 16px 0 16px;
  }
  .EL-connfirmInfoField {
    .MuiPaper-root {
      box-shadow: none;
      background-color: #eceef4;
      padding: 10px 16px;
      border-radius: 8px;
      label {
        font-size: 14px;
        color: #666;
      }
      span {
        font-size: 14px;
        color: #888;
      }
      em {
        font-style: normal;
        margin-left: 16px;
        padding-left: 16px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          height: 4px;
          width: 4px;
          position: absolute;
          background: #000;
          left: 0px;
          top: 40%;
          border-radius: 50%;
        }
      }
      p {
        margin: 0px;
      }
    }
    .EL-connfirmInfoInner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .El-confirmStickyRight {
    padding-top: 20px;
  }
  .El-buttonBox {
    margin-top: 36px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 10px 0px;
    a {
      color: #2563eb;
      text-decoration-color: #2563eb;
      padding: 0 8px;
      display: flex;
      align-items: center;
      svg {
        font-size: 6px;
        margin-right: 8px;
      }
    }
  }

  .El-confirmBanner {
    margin-top: 36px;
    img {
      max-width: 100%;
    }
  }
  // remaining chip container
  .El-RemaningChip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    padding: 12px 16px;
    margin-top: 16px;
    border-radius: 8px;
    background: #ffdcdb;
    font-size: 14px;

    .MuiChip-label {
      white-space: initial;
      color: #666;
    }
    button {
      border-radius: 30px;
      color: #fff;
      font-size: 14px;
      text-transform: capitalize;
      min-width: 200px;
      background: #00b370;
      &:hover {
        background: #00a267;
        color: #fff;
      }
    }
    @media (max-width: 768px) {
      font-size: 12px;
      display: grid;
      .MuiChip-label {
        padding: 0px;
      }
      button {
        margin-top: 8px;
      }
    }
  }
  @media (max-width: 768px) {
    padding-top: 80px;
    .EL-conformContaner {
      padding: 0px;
      .EL-PDInfo {
        padding-right: 0px;
        border-bottom: 2px dashed #ccc;
        padding-left: 56px;
      }
      .EL-PDInfoBottom {
        padding-left: 56px;
        padding-right: 16px;
        padding-top: 16px;
        h6 {
          font-weight: 600;
          font-size: 18px;
        }
        .EL-connfirmInfoField {
          .MuiPaper-root {
            padding: 10px 12px;
          }
          .EL-connfirmInfoInner {
            p {
              b {
                display: block;
                font-size: 16px;
              }
              em {
                margin: 0px;
                padding-left: 0px;
                font-size: 14px;
                font-weight: 600;
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
      .MuiGrid-grid-sm-12 {
        padding: 0px;
      }
      .El-confirmStickyRight {
        padding-top: 16px;
      }
      .El-confirmBanner {
        margin-top: 24px;
      }
      .El-buttonBox {
        margin: 36px 0px;
      }
    }
    h4 {
      font-size: 1.3em;
      margin-bottom: 0px;
    }
    .MuiPaper-root {
      box-shadow: none;
      &.EL-bookingConfirmPaper {
        margin-top: 12px;
        & > .MuiCardContent-root {
          padding: 16px 0px;
        }
      }
    }
  }
}
