@import '@styles/_variables.scss';
.bookingContainer {
  padding-left: $spacing-9xl !important;
  padding-right: $spacing-9xl !important;
  padding-top: 120px;
  min-height: 100vh;

  .El-bookingActionsList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 4px;
    margin-bottom: 12px;
    .EL-confrimAction {
      margin-right: 16px;
      width: 50%;
      margin-bottom: 0px;
      &:last-child {
        margin-right: 0px;
        button {
          background: #000;
          color: #fff;
        }
      }
    }
    button {
      border-radius: 24px;
      min-width: 200px;
      font-weight: 600;
      text-transform: capitalize;
      color: #000;
      .MuiSvgIcon-root {
        margin-right: 6px;
      }
      &.MuiButton-contained {
        min-width: 350px;
      }
    }
  }
  .EL-share {
    background: #eceef4;
    position: absolute;
    right: 0px;
    bottom: 8px;
    color: #1c1b1f;
    display: none;
  }
  .EL-PDGuestFormInner {
    padding: 30px 0px;
    border-bottom: 6px solid #eceef4;
    border-top: 4px solid #eceef4;
    input {
      font-weight: $font-weight-bold;
      color: $color-secondary;
    }
    & .MuiSelect-select {
      font-weight: $font-weight-bold;
    }
  }
  .MuiTypography-h6 {
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    color: $color-secondary;
    align-items: center;
    justify-content: space-between;
    display: flex;

    button {
      color: $blue-light;
      font-size: $font-size-md;
      font-weight: $font-weight-bold;
      text-transform: capitalize;
      span {
        margin-right: 6px;
      }
      img {
        max-width: 18px;
      }
      svg {
        font-size: $font-size-3xl;
      }
    }
  }

  .EL-PDInfo {
    padding-top: 20px !important;
    .MuiPaper-root {
      box-shadow: none;
      padding-bottom: 28px;
      border-bottom: 1px dashed #e0e0e0;
      .MuiCardMedia-root {
        border-radius: $spacing-xxs;
      }

      .MuiTypography-h5 {
        font-size: $font-size-xl;
        font-weight: $font-weight-bold;
      }
      .MuiTypography-subtitle1 {
      }

      .MuiBox-root {
        &:first-child {
          width: auto;
        }
        width: 100%;
        .css-utspl1-MuiCardContent-root:last-child {
          padding-bottom: 0px;
          padding-right: 0px;
        }
        .css-utspl1-MuiCardContent-root:last-child {
          padding-bottom: 0px;
          padding-right: 0px;
        }
      }
    }
  }
  .EL-bookingStatusChip {
    min-width: 150px;
    margin-bottom: 16px;
    font-weight: 600;
    &.MuiChip-filledSuccess {
      background-color: #cff1e6;
      color: #10b981;
    }
    &.MuiChip-filledError {
      background-color: #ffe5d1;
      color: #ff7c19;
    }
    &.MuiChip-filledWarning {
      background-color: #ffdcdb;
      color: #ff4e4a;
    }
  }
  .EL-PDBack {
    position: relative;
    button {
      &.EL-backNave {
        font-size: 26px;
        color: #364d59;
        font-weight: 600;
        padding-left: 0px;
        text-transform: capitalize;
        svg {
          font-size: 20px;
          margin-left: -41px;
          margin-right: 15px;
        }
      }
      &.EL-backHeader {
        font-size: $font-size-4xl;
        color: $color-secondary;
        font-weight: $font-weight-bold;
        padding-left: 0px;
        text-transform: capitalize;
        svg {
          font-size: $font-size-xl;
          margin-left: -41px;
          margin-right: 15px;
        }
      }
    }
    .EL-bookingId {
      color: #2563eb;
      margin-top: -8px;
    }
  }
  .El-paymentDetail {
    padding: $spacing-md;
    // position: sticky;
    // top: 90px;
    margin-bottom: 16px;
    border-radius: $spacing-sm;
    &.EL-RedeemClash {
      padding: 16px 20px 16px 30px !important;
    }
    .MuiCardHeader-root {
      padding-bottom: 0px;
      padding-top: 0px;
    }
    & .EL-priceLoader {
      position: absolute;
      right: $spacing-xl;
      top: 26px;
      span {
        height: $space-22px !important;
        width: $space-22px !important;
      }
    }
    &.EL-blur {
      filter: blur(2px);
      -webkit-filter: blur(2px);
    }
    .MuiList-root {
      padding-top: 0px;
      color: $color-secondary;
      li {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-bottom: 2px;
        margin-top: 6px;
        & .MuiListItemText-root {
          display: flex;
          align-items: center;
          & .MuiListItemText-secondary {
            color: #3bb95e;
            padding-left: 4px;
          }
        }
        & .EL-discountedPrice {
          color: #3bb95e;
        }
        & .EL-discountCode {
          .MuiTypography-body1 {
            white-space: nowrap;
            align-items: center;
            display: flex;
            svg {
              max-width: 18px;
              margin-left: 4px;
              height: auto;
            }
          }
        }
        &.El-pdTotal {
          margin-bottom: 8px;
          border-bottom: 1px solid #e0e0e0;
          border-top: 1px solid #e0e0e0;
          margin-top: 8px;
          padding-top: 8px;
          padding-bottom: 8px;
        }
        &.El-payble {
          font-weight: 600;
          font-size: 16px;
          .MuiTypography-root {
            font-weight: 600;
          }
        }
        // &:last-child {
        //   padding-top: $spacing-xxs;
        //   padding-bottom: $spacing-xxs;
        // }
      }
      .El-TotalPayble {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 10px;
        .MuiTypography-body1 {
          padding-right: 12px;
        }
      }
    }
    .EL-PayNowForm {
      margin: 0px;
      width: 100%;
      margin-top: 16px;
    }
    .MuiTypography-body1 {
      // font-size: $font-size-lg;
    }
    .El-pdTotal {
      padding-top: $spacing-xs;
      padding-bottom: $spacing-xs;
      .MuiTypography-root,
      .MuiListItemSecondaryAction-root {
        font-size: 16px;
        font-weight: $font-weight-bold;
        color: $color-secondary;
      }
    }
    .MuiFormControlLabel-root {
      & .Mui-checked {
        color: $blue-light;
      }
      .MuiFormControlLabel-label {
        font-size: $font-size-default;

        a {
          color: $blue-light;
          text-decoration-color: $blue-light;
        }
      }
    }
    button {
      border-radius: $border-radius-xl;
      background: $primary-black;
      text-transform: capitalize;
      font-weight: $font-weight-bold;
      font-size: $font-size-md;
      color: $primary-white;
      &.Mui-disabled {
        background: #ccc;
      }
      .MuiCircularProgress-root {
        height: $space-22px !important;
        width: $space-22px !important;
      }
      .MuiCircularProgress-svg {
        color: $primary-white;
        font-size: 13px;
      }
      &.MuiButton-containedSuccess {
        background: #40b64d;
      }
    }
    .EL-bookingAcation {
      display: block;
    }
    .MuiTypography-h5 {
      font-weight: $font-weight-bold;
      color: $color-secondary;
      font-size: 20px;
    }
  }

  .EL-Content {
    .MuiTypography-h6 {
      font-size: 20px;
      margin: 0px;
    }
    ul {
      padding: 0px $spacing-sm;
      margin: 0px;
      li {
        padding: $spacing-xxs 0px;
        a {
          color: $blue-light;
          text-decoration-color: $blue-light;
        }
      }
    }
    .El-contentExpand {
      &:before {
        opacity: 1;
      }
    }
  }

  .EL-guestDetailForm {
    padding: $spacing-md 0 0px 0px;
    & .MuiInputBase-formControl {
      &:hover {
        &:before {
          border-bottom: 1px solid $gray-menu !important;
        }
      }
    }
    & .Mui-focused {
      &:after,
      &:before {
        border-bottom: 1px solid $gray-menu !important;
      }
      color: $gray-menu;
    }
    .Mui-error:after,
    .Mui-error:before {
      border-bottom-color: $gray-menu !important;
    }
    label {
      &.Mui-focused {
        color: $gray-menu;
      }
      &.Mui-error {
        color: $gray-menu;
      }
      & span.Mui-error {
        color: $gray-menu;
      }
    }
    p.Mui-error.Mui-required {
      color: #ff4f4f !important;
      font-size: $font-size-default;
      margin: 3px 0 0 0px;
      line-height: 1.66;
      height: 19px;
    }
  }
  .El-checkinCheckoutBooking {
    .detailCheckinCheckoutMain {
      .detailCheckinCheckout {
        display: flex;
        align-items: flex-start;
        justify-content: start;
        padding: 10px 0px;
        .detailCheckinCheckoutField {
          width: 60%;
          .MuiTextField-root {
            width: 50%;
          }
        }

        .detailCheckinCheckoutButton {
          border: none;
          & button.El-addGuestButton {
            border-left: 1px solid $gray-pastel;
            border-radius: 0px;
            width: auto;
            padding-left: 30px;
          }
        }
      }
    }
  }
  .El-reviewLoginCta {
    background-color: #eaf0fd;
    justify-content: space-between;
    border-color: #2563eb;
    border-radius: 8px;
    color: #2563eb;
    margin-top: 16px;
    &:hover {
      background-color: #eaf0fd;
    }
    svg {
      color: #2563eb;
    }
  }
}

// payment faield modal ui
.EL-FieldModal {
  .MuiDialog-paper {
    border-radius: $spacing-sm;
  }
  .EL-FieldModalContent {
    text-align: center;
    .EL-FieldModalText {
      padding: 30px 30px $spacing-sm 30px;
      h4 {
        font-size: $font-size-3xl;
        font-weight: $font-weight-bold;
        color: $color-primary;
        padding: $spacing-md 0px 13px 0px;
        margin: 0px;
      }
      .MuiTypography-body1 {
        margin: 0px;
        padding-bottom: 0px;
      }
      button {
        border-radius: $border-radius-xl;
        margin-top: 40px;
        background: #000;
        height: 48px;
      }
      .MuiSvgIcon-root {
        font-size: 66px;
        color: $color-primary;
      }
    }
  }

  @media (max-width: 768px) {
    .MuiDialog-paper {
      margin: 0px;
      position: fixed;
      bottom: 0px;
      border-radius: 0px;
      -webkit-border-top-left-radius: 12px;
      -webkit-border-top-right-radius: 12px;
      -moz-border-radius-topleft: 12px;
      -moz-border-radius-topright: 12px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      width: 100%;
    }
    .EL-FieldModalContent {
      .EL-FieldModalText {
        padding: 30px 0px $spacing-sm 0px;
        h4 {
          font-size: $font-size-xl;
          padding: 6px 0px;
        }
        .MuiSvgIcon-root {
          font-size: 53px;
          color: $color-primary;
        }
      }
    }
  }
}
.El-checkinCheckoutBooking {
  margin-top: -10px;
  &.El-checkinCheckoutDisable {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: #ebebeb;
      opacity: 0.4;
      border-radius: 11px;
      z-index: 9;
    }
  }
}
.El-PartialSwitch {
  &.El-paymentDetail {
    .MuiCardHeader-root {
      // padding: 0px;
    }
    .El-bookigDetailSwitch {
      .MuiFormControlLabel-root {
        flex-direction: row-reverse;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin: 0px;
        .MuiTypography-root {
          font-size: 18px !important;
          font-weight: 600;
          @media (max-width: 768px) {
            font-size: 18px !important;
          }
        }
      }
      .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background-color: #000;
      }
      .Mui-checked {
        color: #fff;
      }
    }

    .MuiCardContent-root {
      padding-top: 0px;
      padding-bottom: 12px !important;
      color: #666;
      font-size: 14px;
      @media (max-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    @media (max-width: 768px) {
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
      .MuiCardHeader-root {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}
.EL-minStay-alert {
  margin-top: 10px;
  border-radius: 0.725rem !important;
  .MuiAlert-icon {
    display: flex;
    align-items: center;
    padding: 0px;
    color: #ef4444 !important;
  }
  .MuiAlert-message {
    padding-top: 4px;
    padding-bottom: 4px;
    color: #ef4444;
  }
}
