@import '@styles/_variables.scss';
.bookingContainerMobile {
  //   padding-left: 0px !important;
  //   padding-right: 0px !important;
  padding-top: 70px;
  min-height: 100vh;

  .EL-PDInfoBottom {
    padding: 16px 16px 0 16px;
  }
  .EL-bookingDetailContactMobile {
    border: none;
    width: 100%;
    padding: 0px;
    margin: 16px 0 0 0px;
    .MuiCardContent-root {
      padding-bottom: 0px;
    }
  }
  .El-booking-detail-priceInfo {
    border: none;
    padding: 0px;
    margin: 0px;
    margin: 16px 0 0 0px;
    width: 100%;
  }
  .El-bookingActionsList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0px;
    margin-bottom: 0px;

    .EL-confrimAction {
      margin-right: 16px;
      width: 50%;
      margin-bottom: 0px;
      &:last-child {
        margin-right: 0px;
        button {
          background: #000;
          color: #fff;
        }
      }
    }
    button {
      border-radius: 24px;
      min-width: 150px;
      font-weight: 600;
      text-transform: capitalize;
      color: #000;
      .MuiSvgIcon-root {
        margin-right: 6px;
      }
      &.MuiButton-contained {
        min-width: 350px;
      }
    }
  }
  .EL-PDGuestFormInner {
    padding: 30px 0px;
    border-bottom: 6px solid #eceef4;
    border-top: 6px solid #eceef4;
    .MuiTypography-caption {
      font-size: $font-size-default;
    }
    input {
      font-weight: $font-weight-bold;
      color: $color-secondary;
    }
    & .MuiSelect-select {
      font-weight: $font-weight-bold;
    }
  }
  .MuiTypography-h6 {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    align-items: center;
    justify-content: space-between;
    display: flex;
    button {
      color: $blue-light;
      font-size: $font-size-default;
      font-weight: $font-weight-bold;
      text-transform: capitalize;
      span {
        margin-right: $spacing-3xs;
      }
      img {
        max-width: $spacing-sm;
      }
      svg {
        font-size: $font-size-3xl;
      }
    }
  }
  .EL-bookingStatusChip {
    min-width: 130px;
    margin-bottom: 16px;
    font-weight: 600;
    &.MuiChip-filledSuccess {
      background-color: #cff1e6;
      color: #10b981;
    }
    &.MuiChip-filledError {
      background-color: #ffe5d1;
      color: #ff7c19;
      margin-top: -16px;
      margin-left: -16px;
      border-radius: 0px;
      -webkit-border-bottom-right-radius: 10px;
      -moz-border-radius-bottomright: 10px;
      border-bottom-right-radius: 10px;
    }
    &.MuiChip-filledWarning {
      background-color: #10b981;
      color: #fff;
    }
  }
  .EL-PDBack {
    position: relative;
    padding: 0px 16px;
    button {
      background-color: none;
      &.EL-backHeader {
        font-size: 20px;
        color: $color-secondary;
        font-weight: $font-weight-bold;
        padding-left: 0px;
        text-transform: capitalize;
        margin-left: 6px;
        svg {
          font-size: $font-size-xl;
          margin-left: -41px;
          margin-right: 15px;
        }
      }
      &.EL-share {
        position: absolute;
        right: 16px;
        bottom: 0px;
        background: #eceef4;
        color: #1c1b1f;
        display: none;
      }
    }
    .EL-bookingId {
      color: #2563eb;
      margin: 0px;
      margin-top: -8px;
    }
  }

  .El-paymentDetail {
    padding: $spacing-md 0px;
    // position: sticky;
    // top: 90px;
    // border-radius: $spacing-sm;
    color: $color-secondary;
    border: none;
    border-bottom: 6px solid #eceef4;
    .MuiCardContent-root {
      padding-bottom: 0px;
      font-size: 14px;
      padding-top: 4px;
    }
    & .EL-priceLoader {
      position: absolute;
      right: $spacing-md;
      top: 0px;
      span {
        height: $space-22px !important;
        width: $space-22px !important;
      }
    }
    // &.EL-blur {
    //   filter: blur(2px);
    //   -webkit-filter: blur(2px);
    // }
    .MuiCardHeader-root {
      padding-top: 0px;
      padding-bottom: 0px;
      span {
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
      }
    }
    .MuiList-root {
      padding-top: 0px;
      padding-bottom: $spacing-md;
      li {
        padding-top: 2px;
        padding-bottom: 2px;
        & .MuiListItemText-root {
          display: flex;
          align-items: center;
          & .MuiListItemText-secondary {
            color: #3bb95e;
            padding-left: 4px;
          }
        }
        .MuiTypography-root {
          font-size: 14px;
          display: flex;
          align-items: center;
          svg {
            max-width: 16px;
            margin-left: 4px;
            height: auto;
          }
        }
        &.El-payble {
          font-weight: 600;
          font-size: 16px;
          .MuiTypography-root {
            font-weight: 600;
          }
        }
        & .EL-discountedPrice {
          color: #3bb95e;
        }
      }
    }

    .El-TotalPayble {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 10px;
    }
    .El-pdTotal {
      margin-bottom: 8px;
      border-bottom: 1px solid #e0e0e0;
      border-top: 1px solid #e0e0e0;
      margin-top: 12px;
      padding-top: 8px;
      padding-bottom: 8px;
      .MuiTypography-root,
      .MuiListItemSecondaryAction-root {
        font-size: $font-size-md;
        font-weight: $font-weight-bold;
      }
    }
    .MuiFormControlLabel-root {
      & .Mui-checked {
        color: $blue-light;
      }
      .MuiFormControlLabel-label {
        font-size: $font-size-default;
        a {
          color: $blue-light;
          text-decoration-color: $blue-light;
        }
      }
    }
    button {
      border-radius: $border-radius-xl;
      background: $primary-black;
      height: 46px;
      text-transform: capitalize;
      font-weight: $font-weight-bold;
      font-size: $font-size-md;
      color: $primary-white;
      &.Mui-disabled {
        background: #000;
      }
      .MuiCircularProgress-root {
        height: $space-22px !important;
        width: $space-22px !important;
      }
      .MuiCircularProgress-svg {
        color: $primary-white;
        font-size: 13px;
      }
      &.MuiButton-containedSuccess {
        background: #40b64d;
        &.Mui-disabled {
          background: #000;
        }
      }
    }
  }

  .EL-paymentAction {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px $spacing-sm;
    .EL-paymentActionRight {
      width: 50%;
    }
    .EL-paymentActionLeft {
      width: 50%;
      p {
        margin: 0px;
        font-size: $font-size-default;
      }
      b {
        font-size: $font-size-lg;
      }
      a {
        padding-left: 5px;
        text-decoration: underline;
        font-weight: $font-weight-bold;
        font-size: $font-size-default;
        color: $blue-light;
      }
    }
  }
  .EL-Content {
    ul {
      padding: 0px $spacing-sm;
      margin-bottom: 0px;
      li {
        padding: $spacing-xxs 0px;
        font-size: $font-size-md;
        a {
          color: $blue-light;
          text-decoration-color: $blue-light;
        }
      }
    }
    .El-contentExpand {
      &:before {
        opacity: 1;
      }
    }
  }

  .EL-guestDetailForm {
    padding: $spacing-md 0 0px 0px;
    & .MuiInputBase-formControl {
      &:hover {
        &:before {
          border-bottom: 1px solid $gray-menu !important;
        }
      }
    }
    & .Mui-focused {
      &:after,
      &:before {
        border-bottom: 1px solid $gray-menu !important;
      }
      color: $gray-menu;
    }
    .Mui-error:after,
    .Mui-error:before {
      border-bottom-color: $gray-menu !important;
    }
    label {
      &.Mui-focused {
        color: $gray-menu;
      }
      &.Mui-error {
        color: $gray-menu;
      }
      & span.Mui-error {
        color: $gray-menu;
      }
    }
    p.Mui-error.Mui-required {
      color: #ff4f4f !important;
      font-size: $font-size-sm;
      margin: 3px 0 0 0px;
      line-height: 1.66;
      height: 19px;
    }
  }
  .El-reviewLoginCta {
    background-color: #eaf0fd;
    justify-content: space-between;
    border-color: #2563eb;
    border-radius: 8px;
    color: #2563eb;
    margin-top: 12px;
    margin-bottom: -8px;

    &:hover {
      background-color: #eaf0fd;
    }
    svg {
      color: #2563eb;
    }
  }
  @media (max-width: 768px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
    & ~ .elFooterMain {
      margin-bottom: $spacing-9xl;
      & .MuiFab-circular {
        z-index: 999;
        &.EL-floatAction {
          &.EL-whatsappIcon {
            bottom: 185px;
          }
          &.EL-CallIcon {
            bottom: 125px;
          }
        }
      }
    }
    .EL-guestDetailForm {
      .MuiGrid-grid-xs-12 {
        padding: 8px 0 0px 40px;
      }
    }
    .EL-PDInfo {
      padding: 1rem 1rem 0px 1em;

      .MuiPaper-root {
        color: $color-secondary;
        .MuiTypography-h5 {
          font-size: 1em;
          font-weight: $font-weight-bold;
        }
        .MuiCardMedia-root {
          width: 114px;
          height: 100px;
        }
        .MuiTypography-subtitle2 {
          font-size: $font-size-default;
          padding-top: $spacing-xs;
          color: $color-secondary;
        }
      }
    }
    .EL-PDcheckinCheckout {
      padding: $spacing-sm $spacing-sm;
    }
    .detailCheckinCheckoutMain .detailCheckinCheckout {
      margin-bottom: 4px;
    }
    .EL-PDGuestForm {
      padding: $spacing-sm $spacing-sm;
      border-bottom: 6px solid #eceef4;
      border-top: 4px solid #eceef4;
      .EL-PDGuestFormInner {
        border: none;
        padding: 0px;
      }
    }

    .EL-Content {
      border-bottom: 6px solid #eceef4;

      padding: $spacing-sm $spacing-sm;
      .MuiTypography-h6 {
        margin: 0px;
      }
      p {
        margin: 0px;
      }
      .EL-ContentAccordion {
        padding: 0px;
      }
      .MuiAccordionDetails-root {
        padding: 0px;
      }
      ul {
        padding: 0px 0 0 26px;
        margin: 0px;
        li {
          font-size: $font-size-default;
        }
      }
    }
    .EL-PaymentDetails {
      padding: $spacing-sm 0px;
      // border-bottom: 6px solid #eceef4;
      z-index: 99;
      .El-paymentDetail {
        padding: 0px;
        border: none;
        .MuiCardActions-root {
          padding: 0 $spacing-sm;
        }
        .MuiCardHeader-root span {
          font-size: $font-size-lg;
        }
      }
    }
  }
}
.EL-PDInfo {
  .MuiPaper-root {
    box-shadow: none;
    .MuiChip-root {
      background: #fff;
      border: none;
      font-weight: 600;
      top: 50%;
      margin-top: -15px;
      left: 50%;
      margin-left: -40px;
      font-size: 14px;
      color: #ed4441;
      height: 27px;
    }
    .MuiCardMedia-root {
      border-radius: $spacing-xxs;
    }
    .MuiTypography-h5 {
      font-size: $font-size-xl;
      font-weight: $font-weight-bold;
      color: $color-secondary;
    }
    .MuiTypography-subtitle1 {
    }
    .MuiTypography-subtitle2 {
      font-size: $font-size-md;
      font-weight: $font-weight-bold;
      padding-top: $spacing-xs;
    }
  }
  @media (max-width: 768px) {
    padding: $spacing-sm $spacing-sm;

    .MuiPaper-root {
      .MuiChip-root {
        margin-top: -24px;
      }
      .MuiTypography-h5 {
        font-size: $font-size-default;
        font-weight: $font-weight-bold;
      }
      .MuiCardMedia-root {
        width: 114px;
      }
      .MuiTypography-subtitle2 {
        font-size: $font-size-default;
        padding-top: 0px;
      }
      .MuiBox-root {
        width: 100%;
        .MuiCardContent-root {
          padding-bottom: 0px;
        }
        &:first-child {
          width: auto;
        }
      }
    }
  }
}
