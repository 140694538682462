@media (max-width: 768px) {
  .profileContainerMobile {
    &.EL-booingsMain {
      padding-top: 80px;
      background: #eceef4;
      .aboutContent {
        padding: 0px;
      }
    }
    &.EL-profileMain {
      padding-top: 57px;
      padding-bottom: 20px;
    }
  }
}
.El-MybookingList {
  min-height: 75vh;
  .El-MybookingListItem {
    margin: 32px 0 20px 0px;
    &:first-child {
      margin-top: -48px;
    }
    h6 {
      font-size: 24px;
      font-weight: 600;
      color: #000;
      margin-bottom: -12px;
    }
    @media (max-width: 768px) {
      &:first-child {
        margin-top: 0px;
      }
    }
  }

  .EL-bookingInfoCard {
    padding: 16px;
    border-radius: 16px !important;
    margin-top: 24px;
    box-shadow: none !important;

    .El-bookingStatus {
      @media (min-width: 769px) {
        border-radius: 0px;
        -webkit-border-top-left-radius: 16px;
        -webkit-border-bottom-right-radius: 16px;
        -moz-border-radius-topleft: 16px;
        -moz-border-radius-bottomright: 16px;
        border-top-left-radius: 16px;
        border-bottom-right-radius: 16px;
        margin-top: -29px;
        margin-left: -16px;
        font-size: 14px;
        padding: 0 20px;
        height: 26px;
        font-weight: 600;
      }
      .MuiChip-label {
        //   font-weight: 600;
      }
      &.El-bookingStatusinfo {
        background-color: #ffe5d1;
        color: #ff7c19;
      }
      &.El-bookingStatussuccess {
        background-color: #cff1e6;
        color: #10b981;
      }
      &.El-bookingStatusprimary {
        background-color: #10b981;
        color: #fff;
      }
      &.El-bookingStatuserror {
        background-color: #ffdcdb;
        color: #ff4e4a;
      }
    }
    .EL-bookingInfoPaper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px;
      background: #eceef4;
      border-radius: 8px;

      .MuiChip-root {
        background: none;
        font-weight: 600;
        &.MuiChip-colorSuccess {
          color: #10b981;
        }
      }
    }
    .EL-bookingActions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      div {
        display: flex;
        align-items: center;
        .EL-confrimAction {
          margin: 16px 0 0 0px;
          button {
            min-width: 150px;
            img {
              margin-right: 0.25rem;
            }
          }
          &:last-child {
            margin-left: 20px;
          }
          &:first-child {
            margin-left: 0px;
          }
        }
      }
    }

    .EL-bookingListProperty {
      padding: 4px 0px 16px 0px;
    }
    .EL-bookingsRemaining {
      background-color: #ffdcdb;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      border-radius: 8px;
      height: 48px;
    }
  }
  // menu css start
  @media (max-width: 768px) {
    .El-MybookingListItem {
      h6 {
        font-size: 20px;
        padding: 0 16px;
      }
      .EL-bookingInfoCard {
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 0px !important;
        .EL-bookingsRemaining {
          height: 44px;
        }
        .EL-bookingInfoPaper {
          padding: 8px 6px;
          display: flow-root;
          .MuiChip-root {
            flex: 33%;
            box-sizing: border-box;
          }
        }

        .EL-bookingActions div .EL-confrimAction button.MuiButton-outlined {
          min-width: 150px;
          font-size: 0px;
          min-width: inherit;
          &.MuiButton-endIcon {
            display: none;
          }
        }
        .El-bookingStatus {
          border-radius: 0px;
          border-top-left-radius: 0px;
          -webkit-border-bottom-right-radius: 16px;
          -moz-border-radius-bottomright: 16px;
          margin-left: -16px;
          font-size: 14px;
          padding: 0 20px;
          height: 26px;
          margin-top: -29px;
          font-weight: 600;
        }
      }
    }
  }
  .MuiSkeleton-root {
    margin-bottom: 20px;
    border-radius: 16px;
  }
  @media (max-width: 768px) {
    min-height: inherit;
  }
}
// no booking list
.EL-NoBookingListMain {
  min-height: 70vh;
  display: flex;
  align-items: center;
  .EL-NoBookingList {
    margin: 0 auto;
    background: none;
    box-shadow: none;
    text-align: center;
    .MuiCardMedia-root {
      background-size: 290px;
    }
    .MuiTypography-h5 {
      font-size: 22px;
      font-weight: 600;
      color: #000;
    }
    .MuiTypography-body2 {
      font-size: 16px;
    }
    .MuiCardActions-root {
      button {
        margin: 0 auto;
        border-radius: 26px;
        width: 100%;
        max-width: 362px;
        background-color: #000;
        height: 48px;
      }
    }
  }
  @media (max-width: 768px) {
    min-height: 74vh;
  }
}
