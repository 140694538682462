@import '@styles/_variables.scss';
.El-booking-detail-priceInfo {
  position: relative !important;
  top: inherit !important;
}
.EL-bookingDevider {
  clear: both;
  display: block;
  float: left;
  margin-top: 16px !important;
  border-top-width: 3px !important;
  border-color: #eceef4 !important;
  width: 97%;
  margin-left: 24px !important;
}

.bookinfDetailStickyRight {
  // position: sticky;
  // top: 90px;
}
.El-payRemainAction {
  border-radius: 30px !important;
  font-weight: 600;
  margin-top: 16px !important;
  text-transform: capitalize !important;
}

.El-remainContent {
  padding-top: 8px;
  color: #666;
  line-height: 16px;
}

.EL-remailingPrimery {
  font-weight: 600;
  border-bottom: 0 !important;
  margin-bottom: 10px;
  .MuiListItemSecondaryAction-root {
    color: #ef4444;
  }
  .MuiTypography-root {
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .EL-bookingDevider {
    width: 100%;
    margin-left: 0px !important;
  }
}
.blur {
  filter: blur(0.8rem);
}
