.profileContainer {
  padding-top: 82px;
  background-color: #eceef4;
  padding-bottom: 30px;
  .EL-accountBreadcrumb {
    margin: 30px 0 20px 0px;
    padding-left: 8px;
  }
}
.profileHeaderTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 30px;
  border-bottom: 1px solid #ccc;
  button {
    border-radius: 20px;
    background-color: #000;
    &.Mui-disabled {
      background-color: #b2b2b2;
      color: #fff;
    }
  }
  h4 {
    font-weight: 600;
    margin: 0px;
    font-size: 24px;
  }
  h6 {
    color: #666;
    margin: 0px;
    font-size: 14px;
  }
}
.profileContent {
  padding: 16px 46px;
}
.EL-profileMenuList {
  border-top: 1px solid #f1f1f1;
  padding: 8px 8px 20px 8px;
}

.EL-profileMenu {
  position: sticky;
  top: 100px;
  padding: 0px !important;
  // min-height: 62vh;
  .MuiListItem-root {
    margin: 5px 0px;
    .MuiListItemIcon-root {
      min-width: 36px;
      img {
        object-fit: contain !important;
      }
      svg {
        width: 22px;
        height: 22px;
      }
    }
    & .MuiChip-filled {
      background: #000;
      color: #fff;
      min-width: 60px;
      justify-content: flex-start;
      .MuiChip-label {
        font-weight: 600;
      }
    }
    .Mui-selected {
      background-color: #000;
      border-radius: 4px;
      &:hover {
        background-color: #000;
      }
      .MuiListItemText-root {
        span {
          color: #fff;
        }
      }
      .MuiListItemIcon-root {
        img {
          object-fit: contain !important;
        }
        svg {
          color: #fff;
          fill: #fff;
          path {
            fill: #fff;
          }
        }
      }
      & .MuiChip-filled {
        background: #fff;
        color: #000;
      }
    }
  }
  .MuiDivider-root {
    display: none;
  }
  .MuiListItemText-root {
    span {
      font-weight: 600;
    }
  }
  .menuActive {
    .Mui-selected {
      background: #2563eb33;
      border-radius: 8px;
    }
  }
}

.El-FieldBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  .EL-profileDate {
    .MuiFormLabel-root {
      top: -38px;
      position: absolute;
      font-size: 14px !important;
      @media (max-width: 767px) {
        top: -24px;
      }
    }
    .MuiInputAdornment-root {
      button {
        svg {
          max-width: 18px;
        }
      }
    }
    .MuiInputBase-input {
      padding: 4px 0px;
      .MuiIconButton-edgeEnd {
        padding-right: 0px;
      }
    }
  }
  .MuiFormControl-root {
    min-width: 40%;

    input {
      font-weight: 600;
    }
    .MuiAlert-root {
      padding: 0px;
    }
    .MuiSelect-select {
      font-weight: 600;
    }
    .MuiAlert-icon {
      margin: 0 4px 0px 0px;
      display: flex;
      align-items: center;
      svg {
        font-size: 14px;
        color: #10b981;
      }
    }
    .MuiButton-textSecondary {
      color: #2563eb;
      font-weight: 600;
    }
    .MuiAlert-message {
      color: #10b981;
    }
    .MuiFormLabel-root {
      font-size: 18px;
      color: #999999;
    }
    .MuiInput-root {
      &:after,
      &:before {
        border-bottom: 1px solid #e0e0e0 !important;
      }
    }
  }
  .MuiInputBase-input {
    padding-left: 0px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
    border-bottom: 1px solid #e6e6e6 !important;
    border-radius: 0px;
    padding: 0px;
  }
}
// mobile profile ui
.profileContainerMobile {
  padding-top: 80px;
  background-color: #fff;
  .EL-profileMenu {
    padding: 0px;
    position: relative;
    top: inherit;
  }
  .profileContent {
    padding: 0px;
    .El-FieldBox {
      padding: 0px 0px;
      display: block;
      .MuiFormControl-root {
        width: 100%;
        min-width: 100%;
        padding: 24px 0px;
        .MuiFormControl-root {
          padding: 0px 0px;
        }
        .MuiInputBase-formControl {
          margin: 0px;
          padding-right: 2px;
          &.Mui-disabled {
            background: #f7f7fa;
            padding: 0 8px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .EL-profileMenu {
    min-height: inherit;
  }
  .profileContainerMobile {
    .profileContent {
      .El-FieldBox {
        .MuiFormControl-root {
          padding: 16px 0 30px 0px;
        }
      }
    }
    .El-profileUpdate {
      background: #000;
      border-radius: 28px;
      height: 48px;
    }
  }
  .EL-myWallet {
    position: relative;
    z-index: 9999;
    padding: 0px;
    // &:before {
    //   content: '';
    //   position: fixed;
    //   height: 20vh;
    //   width: 100%;
    //   background: #fff;
    // }
    .mui-fixed {
      display: none;
    }
    .aboutContent {
      padding: 0px;
    }
  }
}
