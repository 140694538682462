@import '@styles/_variables.scss';

.el-success-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 50px 40px;
  border-radius: 20px;
  h5 {
    color: #000;
    font-weight: 800;
  }
  .MuiButton-root {
    background-color: #000;
    color: #fff;
    border-radius: $border-radius-xl;
  }
  .successImage {
    height: 160px;
    width: 160px;
  }
  .callbackSuccess-btn {
    &.MuiButtonBase-root {
      width: 100%;
      height: inherit;
    }
    &.MuiButtonBase-root.MuiButton-root:hover {
      &:hover {
        background-color: rgba($primary-black, 0.8);
        color: $primary-white;
      }
    }
  }
}
@media (max-width: 767px) {
  .el-success-container {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
