.MuiPaper-root.E-CallBackpaper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #d1e3fe;
  padding: 20px 24px 16px 24px;
  border-radius: 12px;
  &.EL-homeCallback {
    z-index: 1;
    padding-bottom: 12px;
    margin-top: -30px;
    border-radius: 0px;
    -webkit-border-bottom-right-radius: 12px;
    -webkit-border-bottom-left-radius: 12px;
    -moz-border-radius-bottomright: 12px;
    -moz-border-radius-bottomleft: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  &.EL-propertyListCallback {
    z-index: 1;
    padding-bottom: 12px;
    margin-top: -30px;
    border-radius: 0px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;

    @media (max-width: 767px) {
      z-index: 1;
      padding: 8px 16px;
      border-radius: 4px;
      margin-top: 10px;
      h6 {
        max-width: 68%;
        font-size: 12px;
      }
      button {
        font-size: 14px;
        white-space: nowrap;
      }
    }
  }
  &.EL-modalCallback {
    position: absolute;
    bottom: 0px;
    background: #458df8;
    color: #fff;
    display: block;
    width: 82%;
    left: 5%;
    padding: 4%;
    bottom: 5%;
    border-radius: 4px;

    h6 {
      color: #f2f2f2;
      font-size: 12px;
    }
    button {
      color: #fff;
      padding: 0px;
      text-decoration: underline;
      margin-top: 6px;
    }
  }
  h6 {
    font-size: 16px;
    font-weight: 400;
    margin: 0px;
  }
  button {
    color: #458df8;
    padding: 0px;
    text-decoration: underline;
  }
}
.callback-success-drawer {
  .MuiPaper-root {
    &.MuiDrawer-paper {
      border-radius: 10px;
    }
  }
}
.EL-CallBackModalContent {
  min-width: 510px;
  .El-Property-filter-content {
    h6 {
      font-size: 14px;
      color: #999;
    }
  }
  .EL-CallBackDetailField {
    padding-bottom: 10px;
    // display: flex;
    // align-items: flex-start;
    // justify-content: space-between;
    .ai-center {
      .el-loginField {
        width: 48%;
        margin-left: 0px;
      }
    }
    .el-loginField {
      margin-left: 16px;
      margin-bottom: 16px;
      label {
        margin: 0px;
        display: none;
      }
    }
  }
  .MuiDivider-root {
    margin: 10px 0px 16px 0px;
    @media (max-width: 767px) {
      margin: 4px 0px 10px 0px;
    }
  }
  @media (max-width: 767px) {
    min-width: inherit;
    padding: 42px 20px 0 20px !important;
    .EL-CallBackDetailField {
      display: block;
      width: 100%;
      &.mr-16 {
        margin-right: 16px;
      }
      .el-loginField {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 16px;
        label {
          display: block;
          font-size: 11px;
          color: #888;
          margin-bottom: -2px;
        }
      }
    }
  }
}

// call back Modal
.EL-callBackModal {
  .MuiPaper-root {
    padding: 24px 16px;
    position: relative;
    border-radius: 12px;
    .MuiIconButton-root {
      margin-right: 8px;
      margin-top: 4px;
      svg {
        color: #000;
      }
    }
    .MuiDialogTitle-root {
      h5 {
        margin-top: 18px;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 4px;
      }
    }

    .MuiButton-root {
      height: 48px;
      color: #fff;
      background: #000;
      &.Mui-disabled {
        background: #b2b2b2;
      }
      &.requestCallback-btn {
        border-radius: 24px;
      }
    }
    .callback-success-drawer {
      height: 300px;
    }
  }
  .MuiDivider-wrapper {
    color: #888;
    margin: 10px 0px;
  }
  @media (max-width: 767px) {
    .MuiPaper-root {
      border-radius: 0px;
      padding: 0px;
      &.MuiAppBar-root {
        box-shadow: 0px 2px 16px 0px #00000026;

        .MuiTypography-h6 {
          margin: 0px;
          font-size: 18px;
          color: #000;
          font-weight: 600;
        }
        .MuiIconButton-root {
          margin-top: 0px;
        }
      }
    }
  }
}
